<template>
  <v-dialog
    v-model="openConfirm"
    :max-width="maxWidth"
    @keydown.esc="close"
    persistent
  >
    <v-card>
      <v-card-title class="headline primary--text">{{ title }}</v-card-title>
      <v-card-text v-if="text" class="text-left">{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">{{
          cancelBtn ? cancelBtn : textBtnCancel
        }}</v-btn>
        <v-btn text color="primary" dark @click="confirm">{{
          submitBtn ? submitBtn : textBtnConfirm
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import enums from "@/utils/enums/index.js";

export default {
  name: "ConfirmDialog",
  props: {
    title: { type: String, default: enums.titles.DIALOG_CONFIRMAR },
    text: { type: String, required: true },
    maxWidth: { type: String, required: false,default:"300" },
    submitBtn: { type: String, default: null },
    cancelBtn: { type: String, default: null },
    openConfirm: { type: Boolean, required: true },
  },
  data() {
    return {
      textBtnConfirm: enums.buttons.CONTINUAR,
      dialogConfirm: false,
      textBtnCancel: enums.buttons.CANCELAR,
    };
  },

  methods: {
    close() {
      this.dialogConfirm = false;
      this.$emit("update:openConfirm", false);
    },
    confirm() {
      this.$emit("onConfirm");
      this.close();
    },
  },
};
</script>
